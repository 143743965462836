<template>
  <div>
    <Modal v-model="setupStatus" label-position="left" width="650" @on-visible-change="visibleChange" :footer-hide="true" :mask-closable="false" class-name="vertical-center-modal">
      <div slot="header" class="headerDiv"><span>自定义列</span><span>勾选需要显示的列,拖动列名进行排序</span></div>
      <div class="topDiv">
        <Table :columns="columns" :data="allList" :draggable="true" @on-drag-drop="onDragDrop" border height="500" @on-selection-change="selectChange">
          <template slot-scope="{ row, index }" slot="title">
            <div class="canMove" @mouseenter="mouseEnter(row, index)" @mouseleave="mouseLeave(row, index)">
              <img src="../assets/images/canMove.png" v-if="row.showImg" />
              <span>{{ row.title }}</span>
            </div>
          </template>
        </Table>
      </div>
      <div class="foot">
        <span id="dtbt_qx" class="pageBtn finger btnCancle" @click="cancleDelete">取消</span>
        <span id="dtbt_qr" class="pageBtn finger marginLeft20 btnSure" @click="sureChange">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'tableSetup',
  data() {
    return {
      setupStatus: true,
      columns: [
        {
          type: 'selection',
          align: 'center',
          width: 70,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 70,
        },
        {
          title: '基本信息项目',
          align: 'center',
          slot: 'title',
          minWidth: 200,
        },
      ],
      allList: [], // 项目总列表
      hadSelectList: [],
    }
  },
  props: {
    pageList: Array, // 修改页面显示title列表
    option_page: String, // 当前页面的id 01
  },
  mounted() {
    this.allList = JSON.parse(localStorage.getItem('adminTtitleList'))
    this.dealCheck()
  },
  methods: {
    mouseEnter(row, index) {
      this.$set(this.allList[index], 'showImg', true)
    },
    mouseLeave(row, index) {
      this.$set(this.allList[index], 'showImg', false)
    },
    onDragDrop(index1, index2) {
      console.log(index1, index2)
      if (index1 == index2) {
        return
      }
      let obj = JSON.parse(JSON.stringify(this.allList[index1]))
      if (index1 > index2) {
        this.allList.splice(index2 * 1, 0, obj)
        this.allList.splice(index1 * 1 + 1, 1)
      } else if (index2 - index1 == 1) {
        ;[this.allList[index1], this.allList[index2]] = [this.allList[index2], this.allList[index1]]
      } else {
        this.allList.splice(index2 * 1, 0, obj)
        this.allList.splice(index1 * 1, 1)
      }
      this.allList = JSON.parse(JSON.stringify(this.allList))
      this.$Message.success('移动成功,稍后请确认保存修改')
    },
    selectChange(list) {
      this.hadSelectList = list
      if (list.length == 0) {
        for (let j = 0; j < this.allList.length; j++) {
          this.$set(this.allList[j], '_checked', false)
        }
        return
      }

      for (let j = 0; j < this.allList.length; j++) {
        this.$set(this.allList[j], '_checked', false)
        for (let i = 0; i < this.hadSelectList.length; i++) {
          if (this.allList[j].title == this.hadSelectList[i].title) {
            this.$set(this.allList[j], '_checked', true)
          }
        }
      }
    },
    // 处理已选的
    dealCheck() {
      for (let i = 0; i < this.pageList.length; i++) {
        for (let j = 0; j < this.allList.length; j++) {
          this.allList[j].show = '0'
          this.$set(this.allList[j], 'showImg', false)
          if (this.allList[j].title == this.pageList[i].title) {
            this.hadSelectList.push(this.allList[j])
            this.$set(this.allList[j], '_checked', true)
          }
        }
      }
    },
    cancleDelete() {
      this.setupStatus = false
      this.$emit('cancleBtn', false)
    },
    sureChange() {
      if (this.hadSelectList.length < 1) {
        this.$Message.warning('请最少选择一组数据')
        return
      }
      for (let i = 0; i < this.hadSelectList.length; i++) {
        for (let j = 0; j < this.allList.length; j++) {
          if (this.allList[j].title == this.hadSelectList[i].title) {
            this.$set(this.allList[j], 'show', '1')
          }
        }
      }
      this.$http.post(this.$api.systemOptionItem, { option_page: this.option_page, option_info: this.allList }, false).then(res => {
        this.setupStatus = false
        this.$emit('sureBrn', true)
      })
    },
    visibleChange(statu) {
      this.setupStatus = false
      this.$emit('cancleBtn', false)
    },
  },
}
</script>

<style scoped lang="less">
.canMove {
  position: relative;
  width: 100%;
  img {
    float: left;
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
  }
}
.topDiv {
  margin-bottom: 30px;
}
.headerDiv {
  span:nth-child(1) {
    font-size: 18px;
    color: #333;
    margin-right: 10px;
  }
  span:nth-child(2) {
    font-size: 14px;
    color: #999999;
  }
}
.foot {
  text-align: right;
}
</style>
